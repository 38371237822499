import React, { useState, useEffect } from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import useInterval from "../components/Landing/useInterval";

const StickersPage = (props) => {
  const [slides, setSlides] = useState([]);
  const [currIndex, setCurrIndex] = useState(0);

  useEffect(() => {
    let array = [];
    props.data.stickers.edges.map((edge) => {
      return array.push(edge.node.childImageSharp);
    });
    setSlides(array);
  }, [props.data.stickers.edges]);

  useInterval(() => {
    if (currIndex === slides.length - 1) {
      setCurrIndex(0);
    } else {
      setCurrIndex((prev) => prev + 1);
    }
  }, 2000);

  return (
    <div className="slideshow">
      {slides.map((childImageSharp, i) => (
        <div
          key={i}
          className={`${currIndex === i ? "selected slide" : "slide"}`}
        >
          <GatsbyImage image={getImage(childImageSharp)} alt="sticker" />
        </div>
      ))}
    </div>
  );
};

export const query = graphql`
  query {
    stickers: allFile(filter: { relativeDirectory: { eq: "stickers" } }) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(
              width: 750
              blurredOptions: { width: 5 }
              placeholder: NONE
              quality: 100
            )
          }
        }
      }
    }
  }
`;

export default StickersPage;
